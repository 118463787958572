<!--
  容器高度为宽度的指定比例

  e.g.<base-proportion proportion="10%" />
-->

<script lang="ts" setup>
import { isNumber } from '@qcwp/utils'
const props = withDefaults(defineProps<{
  proportion?: string | number
}>(), {
  proportion: 100,
})

const height = computed(() => {
  if (isNumber(props.proportion))
    return `${props.proportion}%`
  else
    return props.proportion
})
</script>

<template>
  <div>
    <div class="proportion relative pl-100% pt-[var(--height)]" :style="{ '--height': height }">
      <div class="proportion-wrapper absolute top-0 left-0 right-0 bottom-0">
        <slot />
      </div>
    </div>
  </div>
</template>
