<script setup lang='ts'>

</script>

<template>
  <div class="w-full" lt-md="flex py-3 border-(b-1 gray-100)">
    <base-proportion :proportion="56.25" class="rounded-md overflow-hidden " lt-md="w-36 flex-shrink-0">
      <n-skeleton text class="w-full h-full" />
    </base-proportion>
    <div md="mt-2" lt-md="flex-grow w-0 ml-2">
      <div lt-md:hidden>
        <n-skeleton text class="w-50% " />
      </div>
      <n-skeleton text class="w-100% " :repeat="2" />
      <n-skeleton text class="w-40% " />
    </div>
  </div>
</template>
